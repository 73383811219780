<template>
  <div class="help-title">F.A.Q</div>
  <div class="about">
    <div class="title">1. What is the best browser to use ADS? </div>
    <div class="item-cont">
      Google Chrome is the best compatible browser when using ADS.
      If you encounter any issue please try to refresh your web page by press Ctrl + F5 at same time.
      Also please allow popups and cookies from ADS.
    </div>
    <div class="title">2. Why my broswer doesn't popup windows after I click print icon ? </div>
    <div class="item-cont">
      At the top right of Chrome browser, click More. Click Settings. At the bottom, click Advance. Under "Privacy and
      security", click Site settings. Click Pop-ups and redirects. Under "Allow", Add https://www.amparcel.com.
    </div>
    <div class="title">3. Why labels are not display immediately? </div>
    <div class="item-cont">
      When you submit an order, our system interact with the carriers to get your label.
      Please wait for 10 seconds to one single label.
      It may take longer when you make multi-packages shipments.
      So please wait minutes to see your labels if you make batch printing.
    </div>
  </div>
</template>
<style lang="less" scoped>
.help-title {
  margin: 18px 24px 12px 48px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
}

.about {
  margin-left: 260px;
  width: 860px;

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-1);
    margin-top: 46px
  }

  .item-cont {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-2);
  }
}
</style>
